
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




































































































































.ab-test-item {
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding: 0 $--clb-space-3;
    min-height: 58px;
    background-color: $--clb-color-primary__white;
  }

  &__information {
    font-size: $--clb-font-size-xs !important;
    padding: 0 $--clb-space-1;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__title {
    flex: 3 1 0;
    font-size: $--clb-font-size-sm !important;

    &-id {
      display: block;
      font-size: $--clb-font-size-xs;
      line-height: $--clb-font-size-sm;
    }

    &-name {
      margin-right: $--clb-space-2;
      font-weight: 600;
    }

    &-badge {
      position: relative;
      top: -2px;
      font-size: $--clb-font-size-xs - 2px;
      margin-right: $--clb-space-1;
    }
  }

  .el-icon-edit {
    margin-right: 5px;
  }

  .el-button--small {
    padding: 9px 6px;

    &:hover {
      transform: none;
    }
  }

  &__bottom {
    border: 1px solid $--clb-border-color-lighter;
    border-radius: 0 0 $--clb-border-radius $--clb-border-radius;
    border-top: none;
    background-color: $--clb-color-primary__white;
    margin: 0 $--clb-space-2;
    padding: $--clb-space-2 $--clb-space-2;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: $--clb-font-size-sm;
      line-height: 20px;
      padding-bottom: $--clb-space-2;

      .name {
        display: flex;
        flex-grow: 1;

        .el-tag {
          margin-left: $--clb-space-2;
        }
      }

      .conversion-rate {
        width: 25%;
        text-align: right;
        padding-right: $--clb-layout-2;
      }

      &.disabled {
        opacity: 0.6;
      }

      .el-progress {
        width: 30%;

        &-bar__outer {
          border-radius: 0;
        }

        &-bar__inner {
          border-radius: $--clb-border-radius;
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: $--sm) {
  .ab-test-item {
    &__title {
      font-size: $--clb-font-size-base !important;
    }

    &__information {
      font-size: $--clb-font-size-sm !important;
    }

    .el-button--small:not(:last-child) {
      padding: 9px 15px;
    }
  }
}
