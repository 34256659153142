
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




































































































.ab-tests {
  @include page-layout;

  h3 {
    color: $--clb-color__headings;
    margin-top: 0;
  }

  &__item {
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    display: flex;
    align-items: center;
    height: 58px;
    padding: $--clb-space-3;

    .skeleton-box {
      margin: 0;
    }
  }

  .ab-test-item,
  &__item {
    margin-bottom: $--clb-space-4;
  }

  &__add {
    display: flex;
    align-items: center;
    height: 60px;
    line-height: 58px;
    padding: 0 $--clb-space-3;
    border: 1px dashed $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    background: $--clb-skeleton-color;
    transition: $--all-transition;
    cursor: pointer;

    h6 {
      flex: 1 1 0;
      color: $--clb-color-info;
      font-size: $--clb-font-size-sm;
      font-weight: normal;
      width: 100%;
    }

    .ab-tests__add-btn {
      margin: 0;
      padding: 0;
      height: 36px;
      width: 36px;
      font-size: $--clb-font-size-xs;

      span {
        display: none;
      }

      i {
        font-size: $--clb-font-size-sm;
      }

      &:active,
      &:hover,
      &:focus {
        transform: translateY(0) !important;
      }
    }

    &:hover {
      border: 1px solid $--jb-dark-primary-color;
      background: $--color-light;
      transform: translateY(-3px);
      box-shadow: $--box-shadow-light;

      h6 {
        color: $--jb-light-primary-color;
      }
    }
  }
}

@media (min-width: $--sm) {
  .ab-tests {
    h3 {
      margin-bottom: $--clb-layout-1;
    }

    &__add {
      .ab-tests__add-btn {
        width: 108px;

        span {
          display: inline;
        }
      }
    }
  }
}

